import 'aos/dist/aos.css'
import '@/src/styles/globals.css'
import '@/src/index.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { isProdEnv } from '@/utils/environment'

if (isProdEnv()) {
  Sentry.init({
    dsn:
      'https://77c48f15dac34f99a5ddeea93c47e638@o1182487.ingest.sentry.io/6299154',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    
    integrations: [new Sentry.Replay()],
  })
}

function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />
}

export default MyApp
