
export const isBrowser = typeof window !== "undefined";

export function getSipjoyServiceAuthorization() {
  return process.env.NEXT_PUBLIC_SIPJOY_AUTHORIZATION;
}
export function getSipjoyServiceUrl() {
  return process.env.NEXT_PUBLIC_SIPJOY_SERVICE_URL;
}

export function isDevEnv() {
  const isDevEnv = process.env.NODE_ENV === 'development';
  return isDevEnv;
}

export function isProdEnv() {
  const isDevEnv = process.env.NODE_ENV === 'production';
  return isDevEnv;
}


export function getProductsServiceUrl() {
  return process.env.NEXT_PUBLIC_GET_PRODUCTS_SERVICE_URL;
}



export function getStripePublicKey(test) {
  return test 
    ? process.env.NEXT_PUBLIC_STRIPE_KEY_PUBLIC_TEST
    : process.env.NEXT_PUBLIC_STRIPE_KEY_PUBLIC_LIVE;
}

